/*
 * @Author: mrchen
 * @Date: 2021-07-01 17:14:23
 * @Last Modified by: mrchen
 * @Last Modified time: 2021-08-18 15:50:24
 * 管理员类的
 */

import { getRoleList } from '@/apis'
import { reactive } from 'vue'

/**
 * 角色(子管理员)
 * @param {*} init
 * @returns
 */
export function useRoleList(init = false) {
  const state = reactive({
    loading: false,
    list: [],
    pageSize: 10,
    pageNo: 1,
    count: 0
  })
  const initRoleList = async () => {
    state.loading = true
    try {
      const params = {
        pageNo: state.pageNo,
        pageSize: state.pageSize
      }
      const { data } = await getRoleList(params)
      state.list = data?.list || []
      state.count = data?.count || 0
    } finally {
      state.loading = false
    }
  }
  const handleCurrentChange = page => {
    state.pageNo = page
    initRoleList()
  }
  const handleSizeChange = pageSize => {
    state.pageNo = 1
    state.pageSize = pageSize
    initRoleList()
  }
  if (init) {
    initRoleList()
  }
  return {
    state,
    initRoleList,
    handleCurrentChange,
    handleSizeChange
  }
}
