<template>
  <div class="account">
    <div class="department-name">
      <div class="text">子管理员</div>
    </div>
    <div class="add-wrap">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="onAddClick">添加</el-button>
    </div>
    <div class="table-wrapper">
      <el-table
        cell-class-name="custom-table-cell"
        header-row-class-name="custom-table-header"
        :data="state.list"
        v-loading="state.loading"
        empty-text="暂无数据"
        style="width: 100%"
      >
        <el-table-column
          align="left"
          prop="name"
          show-overflow-tooltip
          label="管理组名称"
          width="120"
        ></el-table-column>
        <el-table-column align="left" prop="data_scope" label="管理范围">
          <template #default="{ row }">{{ CONTROL_SCOPE_DICT[row.data_scope] }}</template>
        </el-table-column>
        <el-table-column align="left" prop="appNames" label="权限">
          <template #default="scope">
            <el-popover placement="left-end" popper-class="dark-popover" :width="220" trigger="hover">
              {{ formatAppName(scope.row?.appNames) }}
              <template #reference>
                <div class="ellipsis column-popover">
                  {{ formatAppName(scope.row?.appNames) }}
                </div>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作" width="88">
          <template #default="{ row }">
            <div class="action-wrap">
              <el-button size="mini" type="text" @click="onEdit(row)">编辑</el-button>
              <span v-if="!row.id.includes('_4')">|</span>
              <el-button v-if="!row.id.includes('_4')" size="mini" type="text" @click="onDelete(row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination-block
      v-if="state.list?.length"
      @page-change="handleCurrentChange"
      v-model="state.pageNo"
      :page-size="state.pageSize"
      @size-change="handleSizeChange"
      :total="state.count"
    ></pagination-block>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useRoleList } from '@/hooks/manager'
import { CONTROL_SCOPE_DICT } from '@/common/const'
import { useDeleteConfirm } from '@/hooks/ui'
import { deleteRole } from '@/apis'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import PaginationBlock from '@/components/pagination'
export default defineComponent({
  name: 'accountChild',
  components: { PaginationBlock },
  setup() {
    const router = useRouter()
    const { state, initRoleList, handleCurrentChange, handleSizeChange } = useRoleList(true)
    const deleteConfirm = useDeleteConfirm()
    const onDelete = async account => {
      try {
        if (await deleteConfirm()) {
          await deleteRole(account.id, account.employeeNo)
          initRoleList()
          ElMessage.success('删除成功')
        }
      } catch (error) {
        console.log('error', error)
        ElMessage.success('删除失败')
      }
    }
    const onEdit = item => {
      console.log('reer', item)
      router.push({ name: 'Role', params: { id: item.id } })
    }
    const onAddClick = () => {
      router.push({ name: 'Role' })
    }
    const formatAppName = names => {
      if (!Array.isArray(names)) return names
      return names.join(',')
    }
    return {
      state,
      handleCurrentChange,
      handleSizeChange,
      onDelete,
      onEdit,
      CONTROL_SCOPE_DICT,
      onAddClick,
      formatAppName
    }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
